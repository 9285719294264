export default [{
    _name: 'CSidebarNav',
    _children: [{
            _name: 'CSidebarNavTitle',
            _children: ['Control']
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Panel Principal',
            to: '/master/panel-principal',
            icon: 'cil-speedometer',
            // badge: {
            //     color: 'primary',
            //     text: 'NEW'
            // }
        },
        {
            _name: 'CSidebarNavTitle',
            _children: ['CRM']
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Posibles Clientes',
            to: '/master/posibles-clientes',
            icon: 'cil-fork',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Clientes',
            to: '/master/clientes',
            icon: 'cil-briefcase',
        }, {
            _name: 'CSidebarNavItem',
            name: 'Ofertas',
            to: '/master/ofertas',
            icon: 'cil-cart',
        }, {
            _name: 'CSidebarNavItem',
            name: 'Ventas',
            to: '/master/ventas',
            icon: 'cil-money',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Colaboradores',
            to: '/master/colaboradores',
            icon: 'cil-people',
        },
        {
            _name: 'CSidebarNavItem',
            name: 'Documentos Comerciales',
            to: '/master/documentos-comerciales',
            icon: 'cil-folder',
        },

        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Download CoreUI',
        //     href: 'http://coreui.io/vue/',
        //     icon: { name: 'cil-cloud-download', class: 'text-white' },
        //     _class: 'bg-success text-white',
        //     target: '_blank'
        // },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Try CoreUI PRO',
        //     href: 'http://coreui.io/pro/vue/',
        //     icon: { name: 'cil-layers', class: 'text-white' },
        //     _class: 'bg-danger text-white',
        //     target: '_blank'
        // }
        {
            _name: 'CSidebarNavTitle',
            _children: ['Soporte']
        },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Incidencias de software',
        //     to: '/master/incidencias-de-software',
        //     icon: 'cil-warning',
        // },
        {
            _name: 'CSidebarNavItem',
            name: 'Servicios',
            to: '/master/servicios',
            icon: 'cil-cog',
        },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: 'Perfiles',
        //     to: '/master/perfiles',
        //     icon: 'cil-fingerprint',
        // },
    ]
}]